import { default as _iconsS5X1yAiDSVMeta } from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/core/pages/_icons.vue?macro=true";
import { default as _91_46_46_46page_93zpcxQYNHhyMeta } from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/[...page].vue?macro=true";
import { default as _91child_93ZnaO15YvCXMeta } from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/communities/[slug]/[child].vue?macro=true";
import { default as alleQotoDD6MUdMeta } from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/communities/[slug]/index/alle.vue?macro=true";
import { default as index9Chls6d15YMeta } from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/communities/[slug]/index/index.vue?macro=true";
import { default as kaartpYMR37yZvgMeta } from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/communities/[slug]/index/kaart.vue?macro=true";
import { default as indexaA77eKefOxMeta } from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/communities/[slug]/index.vue?macro=true";
import { default as _91slug_93CKFmfYqk4uMeta } from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/communities/[slug].vue?macro=true";
import { default as _91id_93inilUNoa6pMeta } from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/doopgetuigenissen/[date]/[id].vue?macro=true";
import { default as indexqSFyN6FIcBMeta } from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/doopgetuigenissen/[date]/index.vue?macro=true";
import { default as _91date_937q4QiBbBlfMeta } from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/doopgetuigenissen/[date].vue?macro=true";
import { default as indexgqP6bIq8P1Meta } from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/doopgetuigenissen/index.vue?macro=true";
import { default as _91date_93kODBKbqoHpMeta } from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/evenementen/[uuid]/[date].vue?macro=true";
import { default as indexkJcVjAuXULMeta } from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/evenementen/index.vue?macro=true";
import { default as giftXSUp9BsHR2Meta } from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/geven/gift.vue?macro=true";
import { default as machtigingMvHNeHXagpMeta } from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/geven/machtiging.vue?macro=true";
import { default as _91slug_93nGbYBStrEPMeta } from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/media/[slug].vue?macro=true";
import { default as archiefwmW9HK72HkMeta } from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/media/archief.vue?macro=true";
import { default as indexyDJwO5762hMeta } from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/media/index.vue?macro=true";
import { default as _91slug_93sAXseMJdNfMeta } from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/nieuws/[slug].vue?macro=true";
import { default as indexeBWtQMLUwcMeta } from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/nieuws/index.vue?macro=true";
import { default as zoekenQo2ppFXrecMeta } from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/zoeken.vue?macro=true";
export default [
  {
    name: _iconsS5X1yAiDSVMeta?.name ?? "_icons",
    path: _iconsS5X1yAiDSVMeta?.path ?? "/_icons",
    meta: _iconsS5X1yAiDSVMeta || {},
    alias: _iconsS5X1yAiDSVMeta?.alias || [],
    redirect: _iconsS5X1yAiDSVMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/core/pages/_icons.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46page_93zpcxQYNHhyMeta?.name ?? "page",
    path: _91_46_46_46page_93zpcxQYNHhyMeta?.path ?? "/:page(.*)*",
    meta: _91_46_46_46page_93zpcxQYNHhyMeta || {},
    alias: _91_46_46_46page_93zpcxQYNHhyMeta?.alias || [],
    redirect: _91_46_46_46page_93zpcxQYNHhyMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CKFmfYqk4uMeta?.name ?? undefined,
    path: _91slug_93CKFmfYqk4uMeta?.path ?? "/communities/:slug()",
    meta: _91slug_93CKFmfYqk4uMeta || {},
    alias: _91slug_93CKFmfYqk4uMeta?.alias || [],
    redirect: _91slug_93CKFmfYqk4uMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/communities/[slug].vue").then(m => m.default || m),
    children: [
  {
    name: _91child_93ZnaO15YvCXMeta?.name ?? "communities-slug-child",
    path: _91child_93ZnaO15YvCXMeta?.path ?? ":child()",
    meta: _91child_93ZnaO15YvCXMeta || {},
    alias: _91child_93ZnaO15YvCXMeta?.alias || [],
    redirect: _91child_93ZnaO15YvCXMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/communities/[slug]/[child].vue").then(m => m.default || m)
  },
  {
    name: indexaA77eKefOxMeta?.name ?? undefined,
    path: indexaA77eKefOxMeta?.path ?? "",
    meta: indexaA77eKefOxMeta || {},
    alias: indexaA77eKefOxMeta?.alias || [],
    redirect: indexaA77eKefOxMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/communities/[slug]/index.vue").then(m => m.default || m),
    children: [
  {
    name: alleQotoDD6MUdMeta?.name ?? "communities-slug-index-alle",
    path: alleQotoDD6MUdMeta?.path ?? "alle",
    meta: alleQotoDD6MUdMeta || {},
    alias: alleQotoDD6MUdMeta?.alias || [],
    redirect: alleQotoDD6MUdMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/communities/[slug]/index/alle.vue").then(m => m.default || m)
  },
  {
    name: index9Chls6d15YMeta?.name ?? "communities-slug-index",
    path: index9Chls6d15YMeta?.path ?? "",
    meta: index9Chls6d15YMeta || {},
    alias: index9Chls6d15YMeta?.alias || [],
    redirect: index9Chls6d15YMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/communities/[slug]/index/index.vue").then(m => m.default || m)
  },
  {
    name: kaartpYMR37yZvgMeta?.name ?? "communities-slug-index-kaart",
    path: kaartpYMR37yZvgMeta?.path ?? "kaart",
    meta: kaartpYMR37yZvgMeta || {},
    alias: kaartpYMR37yZvgMeta?.alias || [],
    redirect: kaartpYMR37yZvgMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/communities/[slug]/index/kaart.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: _91date_937q4QiBbBlfMeta?.name ?? undefined,
    path: _91date_937q4QiBbBlfMeta?.path ?? "/doopgetuigenissen/:date()",
    meta: _91date_937q4QiBbBlfMeta || {},
    alias: _91date_937q4QiBbBlfMeta?.alias || [],
    redirect: _91date_937q4QiBbBlfMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/doopgetuigenissen/[date].vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93inilUNoa6pMeta?.name ?? "doopgetuigenissen-date-id",
    path: _91id_93inilUNoa6pMeta?.path ?? ":id()",
    meta: _91id_93inilUNoa6pMeta || {},
    alias: _91id_93inilUNoa6pMeta?.alias || [],
    redirect: _91id_93inilUNoa6pMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/doopgetuigenissen/[date]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexqSFyN6FIcBMeta?.name ?? "doopgetuigenissen-date",
    path: indexqSFyN6FIcBMeta?.path ?? "",
    meta: indexqSFyN6FIcBMeta || {},
    alias: indexqSFyN6FIcBMeta?.alias || [],
    redirect: indexqSFyN6FIcBMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/doopgetuigenissen/[date]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexgqP6bIq8P1Meta?.name ?? "doopgetuigenissen",
    path: indexgqP6bIq8P1Meta?.path ?? "/doopgetuigenissen",
    meta: indexgqP6bIq8P1Meta || {},
    alias: indexgqP6bIq8P1Meta?.alias || [],
    redirect: indexgqP6bIq8P1Meta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/doopgetuigenissen/index.vue").then(m => m.default || m)
  },
  {
    name: _91date_93kODBKbqoHpMeta?.name ?? "evenementen-uuid-date",
    path: _91date_93kODBKbqoHpMeta?.path ?? "/evenementen/:uuid()/:date()",
    meta: _91date_93kODBKbqoHpMeta || {},
    alias: _91date_93kODBKbqoHpMeta?.alias || [],
    redirect: _91date_93kODBKbqoHpMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/evenementen/[uuid]/[date].vue").then(m => m.default || m)
  },
  {
    name: indexkJcVjAuXULMeta?.name ?? "evenementen",
    path: indexkJcVjAuXULMeta?.path ?? "/evenementen",
    meta: indexkJcVjAuXULMeta || {},
    alias: indexkJcVjAuXULMeta?.alias || [],
    redirect: indexkJcVjAuXULMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/evenementen/index.vue").then(m => m.default || m)
  },
  {
    name: giftXSUp9BsHR2Meta?.name ?? "geven-gift",
    path: giftXSUp9BsHR2Meta?.path ?? "/geven/gift",
    meta: giftXSUp9BsHR2Meta || {},
    alias: giftXSUp9BsHR2Meta?.alias || [],
    redirect: giftXSUp9BsHR2Meta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/geven/gift.vue").then(m => m.default || m)
  },
  {
    name: machtigingMvHNeHXagpMeta?.name ?? "geven-machtiging",
    path: machtigingMvHNeHXagpMeta?.path ?? "/geven/machtiging",
    meta: machtigingMvHNeHXagpMeta || {},
    alias: machtigingMvHNeHXagpMeta?.alias || [],
    redirect: machtigingMvHNeHXagpMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/geven/machtiging.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nGbYBStrEPMeta?.name ?? "media-slug",
    path: _91slug_93nGbYBStrEPMeta?.path ?? "/media/:slug()",
    meta: _91slug_93nGbYBStrEPMeta || {},
    alias: _91slug_93nGbYBStrEPMeta?.alias || [],
    redirect: _91slug_93nGbYBStrEPMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/media/[slug].vue").then(m => m.default || m)
  },
  {
    name: archiefwmW9HK72HkMeta?.name ?? "media-archief",
    path: archiefwmW9HK72HkMeta?.path ?? "/media/archief",
    meta: archiefwmW9HK72HkMeta || {},
    alias: archiefwmW9HK72HkMeta?.alias || [],
    redirect: archiefwmW9HK72HkMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/media/archief.vue").then(m => m.default || m)
  },
  {
    name: indexyDJwO5762hMeta?.name ?? "media",
    path: indexyDJwO5762hMeta?.path ?? "/media",
    meta: indexyDJwO5762hMeta || {},
    alias: indexyDJwO5762hMeta?.alias || [],
    redirect: indexyDJwO5762hMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/media/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sAXseMJdNfMeta?.name ?? "nieuws-slug",
    path: _91slug_93sAXseMJdNfMeta?.path ?? "/nieuws/:slug()",
    meta: _91slug_93sAXseMJdNfMeta || {},
    alias: _91slug_93sAXseMJdNfMeta?.alias || [],
    redirect: _91slug_93sAXseMJdNfMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/nieuws/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexeBWtQMLUwcMeta?.name ?? "nieuws",
    path: indexeBWtQMLUwcMeta?.path ?? "/nieuws",
    meta: indexeBWtQMLUwcMeta || {},
    alias: indexeBWtQMLUwcMeta?.alias || [],
    redirect: indexeBWtQMLUwcMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/nieuws/index.vue").then(m => m.default || m)
  },
  {
    name: zoekenQo2ppFXrecMeta?.name ?? "zoeken",
    path: zoekenQo2ppFXrecMeta?.path ?? "/zoeken",
    meta: zoekenQo2ppFXrecMeta || {},
    alias: zoekenQo2ppFXrecMeta?.alias || [],
    redirect: zoekenQo2ppFXrecMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/pages/zoeken.vue").then(m => m.default || m)
  }
]