import validate from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.3_stylelint@16._6g3jdonuskwqzyxpkhs7ayby7m/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45trailing_45slash_45global from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compiler-core_lifdvjnf2z6kn3zdjmnir6ogtq/node_modules/@bttr-devs/nuxt-layer-base/middleware/redirect-trailing-slash.global.ts";
import manifest_45route_45rule from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.3_stylelint@16._6g3jdonuskwqzyxpkhs7ayby7m/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "sofie-page": () => import("/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.1_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compiler-cor_nd7goimyyfohgbos775jbwfzua/node_modules/@bttr-devs/nuxt-layer-sofie/middleware/sofie-page.ts"),
  auth: () => import("/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_next-auth@4.21.1_rollup@4.14.3/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}