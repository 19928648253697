import revive_payload_client_VlnsOUzwCm from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.3_stylelint@16._6g3jdonuskwqzyxpkhs7ayby7m/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KLCy1MY2OM from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.3_stylelint@16._6g3jdonuskwqzyxpkhs7ayby7m/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_yurivJ0GYB from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.3_stylelint@16._6g3jdonuskwqzyxpkhs7ayby7m/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_uqMoMouMHY from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/node_modules/.pnpm/nuxt-site-config@2.2.12_@nuxt+devtools@1.2.0_@vue+compiler-core@3.4.23_nuxt@3.11.2_postcss@8._t6uunylxmm5t7n45ypv73vxtzy/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_m6p76DMfJZ from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.3_stylelint@16._6g3jdonuskwqzyxpkhs7ayby7m/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_2NJSDsIiE7 from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.3_stylelint@16._6g3jdonuskwqzyxpkhs7ayby7m/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/.nuxt/components.plugin.mjs";
import prefetch_client_AHoUbUlLSx from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.3_stylelint@16._6g3jdonuskwqzyxpkhs7ayby7m/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_nvczYjt2s9 from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/node_modules/.pnpm/nuxt-bugsnag@7.3.0_rollup@4.14.3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import i18n_EwTYrFp9yj from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.3.0_rollup@4.14.3_vue@3.4.23/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import formkitPlugin_pZqjah0RUG from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/website/.nuxt/formkitPlugin.mjs";
import plugin_HRAesydAZ4 from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-module-apollo@1.1.1_react-dom@18.2.0_react@18.2.0_rollup@4.14.3_typescript@5.4.5_vue@3.4.23/node_modules/@bttr-devs/nuxt-module-apollo/dist/runtime/plugin.js";
import plugin_fXPhXLhEjc from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_next-auth@4.21.1_rollup@4.14.3/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import chunk_reload_client_EZQzg86SQp from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.3_stylelint@16._6g3jdonuskwqzyxpkhs7ayby7m/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import errors_u5z1rlGhue from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compiler-core_lifdvjnf2z6kn3zdjmnir6ogtq/node_modules/@bttr-devs/nuxt-layer-base/plugins/errors.ts";
import i18n_IuYLbuwY5I from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compiler-core_lifdvjnf2z6kn3zdjmnir6ogtq/node_modules/@bttr-devs/nuxt-layer-base/plugins/i18n.ts";
import apollo_koiEIp4Kxk from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.1_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compiler-cor_nd7goimyyfohgbos775jbwfzua/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/apollo.ts";
import sofie_required_version_ovjPHReCOT from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.1_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compiler-cor_nd7goimyyfohgbos775jbwfzua/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/sofie-required-version.ts";
import apollo_Ttzm7YgvCx from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.3.1_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compile_f4xjmhv2xqnoe2vqvmouekdcfa/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/apollo.ts";
import auth_7y8y6oI1kb from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.3.1_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compile_f4xjmhv2xqnoe2vqvmouekdcfa/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/auth.ts";
import apollo_tALwIKkKAT from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/core/plugins/apollo.ts";
import directives_gmm9TVanhV from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/core/plugins/directives.ts";
import dynamic_vw_client_eh5dkjXzws from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/core/plugins/dynamic-vw.client.ts";
import floating_vue_ZaRVlX9fKN from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/core/plugins/floating-vue.ts";
import gtm_client_ld3zU31MHy from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/core/plugins/gtm.client.ts";
import notifications_sUFtzNS9eT from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/core/plugins/notifications.ts";
import portal_vue_GzRgko8W64 from "/data/www/mozaiek.nl/mozaiek/releases/350/nuxt/core/plugins/portal-vue.ts";
export default [
  revive_payload_client_VlnsOUzwCm,
  unhead_KLCy1MY2OM,
  router_yurivJ0GYB,
  _0_siteConfig_uqMoMouMHY,
  payload_client_m6p76DMfJZ,
  check_outdated_build_client_2NJSDsIiE7,
  components_plugin_KR1HBZs4kY,
  prefetch_client_AHoUbUlLSx,
  plugin_nvczYjt2s9,
  i18n_EwTYrFp9yj,
  formkitPlugin_pZqjah0RUG,
  plugin_HRAesydAZ4,
  plugin_fXPhXLhEjc,
  chunk_reload_client_EZQzg86SQp,
  errors_u5z1rlGhue,
  i18n_IuYLbuwY5I,
  apollo_koiEIp4Kxk,
  sofie_required_version_ovjPHReCOT,
  apollo_Ttzm7YgvCx,
  auth_7y8y6oI1kb,
  apollo_tALwIKkKAT,
  directives_gmm9TVanhV,
  dynamic_vw_client_eh5dkjXzws,
  floating_vue_ZaRVlX9fKN,
  gtm_client_ld3zU31MHy,
  notifications_sUFtzNS9eT,
  portal_vue_GzRgko8W64
]